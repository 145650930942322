import React, { useState, useContext } from "react";
import { SelectField } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import UserLanguageContext from "./../../context/languageContext";
import Emoji from "../Emoji";

const navigation = [
  // { name: "Solutions", href: "#" },
  // { name: "Company", href: "#" },
];

function Home() {
  const { language, setLanguage } = useContext(UserLanguageContext);

  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const onLanguageChange = (e) => {
    const l = e.target.value;
    console.log("setLang component", l);
    setSelectedLanguage(l);
    I18n.setLanguage(l);
    setLanguage(l);
    localStorage.setItem("lang", l);
  };
  return (
    <header className="bg-gradient-to-l from-yellow-200 to-yellow-500">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between py-6 lg:border-none">
          <div className="flex items-center">
            <a href="#">
              <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                Remote Italy
              </h2>
            </a>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className="text-base font-medium text-white hover:text-indigo-50"
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="ml-10 space-x-4">
            <select
              id="language"
              name="language"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              onChange={onLanguageChange}
              value={selectedLanguage}
            >
              <option label="EN" value="en"></option>
              <option label="IT" value="it"></option>
            </select>
          </div>
        </div>
        <div className="flex flex-wrap justify-center space-x-6 py-4 lg:hidden">
          {navigation.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="text-base font-medium text-white hover:text-indigo-50"
            >
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}
export default Home;
