const languages = {
  en: {
    heroTitle1: "Find a Remote job",
    heroTitle2: "Get new listing every week",
    heroButton: "Subscribe",
    footerText1: "Let's keep in touch! Say hello to: ",
    footerText2: "Find us on any of these platforms:",
    footerTitle1: "Resources",
    confirmSubscriptionTitle: "You're almost there! ⏳",
    confirmSubscriptionSub1:
      "Before I can send you updates, you need to click the link that was just sent to you via email",
    confirmSubscriptionSub2:
      "If you don't see it, please check your promotion or spam folder or ",
    confirmSubscriptionEmail: "give me a shout!",
    backSubscriptionEmail: "Find me a job 😍",
    jobsEmptyTitle: "No jobs found",
    jobsEmptyDescription: "Try clearing the filters",
    jobsEmptyButton: "Clear Filters",
  },
  it: {
    heroTitle1: "Trova un lavoro in Smart Working",
    heroTitle2: "ricevi nuovi annunci ogni settimana",
    heroButton: "Iscriviti",
    footerText1: "Rimani in contatto, scrivici a ",
    footerText2: "Contattaci sui social: ",
    footerTitle1: "Risorse",
    confirmSubscriptionTitle: "Ci siamo quasi ⏳",
    confirmSubscriptionSub1:
      "conferma la tua email cliccando sul link che ti ho appena inviato",
    confirmSubscriptionSub2:
      "Se non lo vedi, controlla la cartella di spam o promozioni, altrimenti ",
    confirmSubscriptionEmail: "scrivimi!",
    backSubscriptionEmail: "Trovami un lavoro 😍",
    jobsEmptyTitle: "Nessun lavoro trovato",
    jobsEmptyDescription: "Prova a rimuovere i filtri",
    jobsEmptyButton: "Rimuovi i filtri",
  },
};

export default languages;
