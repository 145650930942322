import "./App.css";
import React, { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ConfirmSubscribe from "./components/ConfirmSubscribe";
import Root from "./roots/root";
import ErrorPage from "./components/ErrorPage";

import { I18n } from "aws-amplify";
import languages from "./languages";
import UserLanguageContext from "./context/languageContext";

I18n.putVocabularies(languages);

const defaultLanguage = () => {
  const languageFromStorage = localStorage.getItem("lang");
  if (languageFromStorage) {
    I18n.setLanguage(languageFromStorage);
    return languageFromStorage;
  } else {
    const detectedLang = navigator.languages
      ? navigator.languages[0]
      : navigator.language || navigator.userLanguage;
    let adaptLang = detectedLang.slice(0, 2);
    if (Object.keys(languages).indexOf(adaptLang) === -1) {
      adaptLang = "en";
    }
    I18n.setLanguage(adaptLang);
    return adaptLang;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/subscribe/confirm",
    errorElement: <ConfirmSubscribe />,
  },
]);

function App() {
  const [language, setLanguage] = useState(defaultLanguage());
  return (
    <UserLanguageContext.Provider value={{ language, setLanguage }}>
      <Router>
        <Routes>
          <Route path="/" element={<Root />} />
          <Route path="/subscribe/confirm" element={<ConfirmSubscribe />} />
          <Route path="*" element={<ErrorPage />} /> 
        </Routes>
      </Router>
      {/* <RouterProvider router={router} /> */}
    </UserLanguageContext.Provider>
  );
}

export default App;
