import React from "react";

import { I18n } from "aws-amplify";
import { useNavigate } from "react-router-dom";

function ConfirmSubscribe() {
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="relative space-y-32 h-14 ">
        <div className="">
          <nav
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-yellow-500 to-amber-500"
            aria-label="Top"
          >
            <div className="flex w-full items-center justify-between py-6 lg:border-none">
              <div className="flex items-center">
                <a href="/">
                  <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                    Remote Italy
                  </h2>
                </a>
              </div>
            </div>
          </nav>
        </div>
        <section className="space-y-3 px-6 sm:px-8 md:mx-auto md:max-w-2xl lg:px-12">
          <h1 className="text-4xl font-medium md:text-5xl">
            {I18n.get("confirmSubscriptionTitle")}
          </h1>
          <p className="text-xl font-light text-black text-opacity-80 md:text-2xl">
            {I18n.get("confirmSubscriptionSub1")}
          </p>
          <p className="text-xl font-light text-black text-opacity-80 md:text-2xl">
            {I18n.get("confirmSubscriptionSub2")}
            <a
              href="mailto:ciao@remote-italy.com?subject=Confirm Subscription"
              className="underline text-orange-600"
            >
              {I18n.get("confirmSubscriptionEmail")}
            </a>
          </p>
          <p>
            <button
              onClick={backToHome}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
            >
              {I18n.get("backSubscriptionEmail")}
            </button>
          </p>
        </section>
      </div>
    </>
  );
}
export default ConfirmSubscribe;
