import React, { useContext, useState } from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { TextField, Button, FieldGroupIcon } from "@aws-amplify/ui-react";
import { ExclamationCircleIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import { createLead } from "./../../graphql/mutations";
import { I18n } from "aws-amplify";
import UserLanguageContext from "./../../context/languageContext";

const apiName = "apisubscribeemail1";
const path = "/subscribe";
const myInit = {
  body: {},
  headers: {},
};

function SignUpForm() {
  //   const [formState, setFormState] = useState(initialState);
  const [email, setEmail] = useState("");
  const { language } = useContext(UserLanguageContext);
  const [hasError, setHasError] = React.useState(false);
  const navigate = useNavigate();

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onClick = async () => {
    try {
      setHasError(false);
      const emailFormat = validateEmail(email);
      if (!emailFormat) {
        setHasError(true);
        return;
      } else {
        // send API
        myInit.body = { email, language };
        await API.post(apiName, path, myInit);
        navigate("subscribe/confirm");
      }
    } catch (err) {
      console.log("error creating SignUp:", err);
    }
  };
  return (
    <div className="flex flex-col md:flex-row justify-center md:items-end items-center">
      <div className="min-w-0 flex-1">
        <label htmlFor="hero-email" className="sr-only">
          Email address
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <EnvelopeIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="email"
            name="hero-email"
            id="hero-email"
            className="block w-full rounded-md border border-gray-300 px-5 py-3 pl-10 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-slate-500 focus:ring-slate-500"
            placeholder="you@example.com"
            aria-invalid="true"
            aria-describedby="email-error"
            value={email}
            onChange={onChangeEmail}
          />
        </div>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-3">
        <button
          type="submit"
          className="block w-full rounded-md border border-transparent bg-amber-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-amber-300 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:px-10"
          onClick={onClick}
        >
          {I18n.get("heroButton")}
        </button>
      </div>
    </div>
  );
}

export default SignUpForm;
