import React from "react";

import Emoji from "../../Emoji";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const countryStyleFlag = (location) => {
  switch (location) {
    case "EMEA":
      return (
        <>
          {" "}
          <Emoji symbol="🇪🇺" label="europe" />
          <span className="ml-1">Europe</span>
        </>
      );
    case "AMER":
      return (
        <>
          <Emoji symbol="🌎" label="amer" />
          <span className="ml-1">Remote Amer</span>
        </>
      );
    case "APAC":
      return (
        <>
          <Emoji symbol="🌎" label="apac" />
          <span className="ml-1">Remote Apac</span>
        </>
      );
    default:
      return location;
  }
};

function CategoryTag({ tags }) {
  return tags.map((t, index) => (
    <span
      key={index}
      className="bg-stone-100 text-stone-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 mr-1"
    >
      {t}
    </span>
  ));
}

export default CategoryTag;
