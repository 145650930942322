import React from "react";
import workRemoteImg from "./../../images/work-from-remote-img.svg";
import vacationImg from "./../../images/vacations.png";
import { I18n } from "aws-amplify";
import SignUpForm from "../SignUpForm";
import JobsFeed from "../JobsFeed";
import { ChevronRightIcon, StarIcon } from "@heroicons/react/20/solid";

function Home() {
  return (
    <>
      <section
        id="mainSection"
        className="bg-white dark:bg-gray-900 container mx-auto"
      >
        <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
          <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div>
                <div className="mt-20">
                  <div className="mt-6 sm:max-w-xl">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                      {I18n.get("heroTitle1")}
                    </h1>
                    <p className="mt-6 text-xl text-gray-500">
                      {I18n.get("heroTitle2")}
                    </p>
                  </div>
                  <form
                    action="#"
                    className="mt-12 sm:flex sm:w-full sm:max-w-lg"
                  >
                    <SignUpForm />
                  </form>
                </div>
              </div>
            </div>

            <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
              <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                  <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
                  <svg
                    className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={392}
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                  </svg>
                </div>
                <div className="relative -mr-40 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
                  <img
                    className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                    src={workRemoteImg}
                    alt="work-from-home"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-gray-900 container mx-auto">
        <JobsFeed />
      </section>
      {/* <section id="mainFeature-intro" className="container mx-auto mt-5">
        <div className="container mx-auto px-16">
          <div className="grid place-content-start">
            <h1 className="max-w-2xl mb-4 ml-1 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white text-start">
              Peace of Mind
            </h1>
            <div className="grid grid-cols-4 text-start">
              <div className="md:col-span-2 col-span-4 text-start">
                <p className="text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
              <div className="md:col-span-2 md:ml-5 col-span-4 sm:mt-3 text-start">
                <p className="text-xl">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section id="mainFeature-cards" className="container mx-auto mt-5">
        <div className="grid md:grid-cols-5 sm:grid-cols-1 gap-4">
          <div className="md:col-start-2 md:col-span-1 col-span-1">
            <div className="rounded-lg shadow-lg bg-white max-w-sm mx-auto">
              <img
                className="rounded-t-lg mx-auto"
                src={vacationImg}
                alt="Vacation Icon"
              />
              <div className="p-6">
                <h5 className="text-gray-900 text-xl font-medium mb-2">
                  Card title
                </h5>
                <p className="text-gray-700 text-base mb-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="md:col-span-1 col-span-1">
            <div className="rounded-lg shadow-lg bg-white max-w-sm mx-auto">
              <img
                className="rounded-t-lg mx-auto"
                src={vacationImg}
                alt="Vacation Icon"
              />
              <div className="p-6">
                <h5 className="text-gray-900 text-xl font-medium mb-2">
                  Card title
                </h5>
                <p className="text-gray-700 text-base mb-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="md:col-span-1 col-span-1">
            <div className="rounded-lg shadow-lg bg-white max-w-sm mx-auto">
              <img
                className="rounded-t-lg mx-auto"
                src={vacationImg}
                alt="Vacation Icon"
              />
              <div className="p-6">
                <h5 className="text-gray-900 text-xl font-medium mb-2">
                  Card title
                </h5>
                <p className="text-gray-700 text-base mb-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
export default Home;
