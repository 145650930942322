/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react";
import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import Emoji from "../Emoji";
import { Amplify, API, graphqlOperation, I18n } from "aws-amplify";

const apiName = "company";
const path = "/jobs";
const myInit = {
  body: {},
  headers: {},
};

const people = [
  {
    id: 1,
    name: "Engineer",
    emoji: "🤓",
    kind: "category",
  },
  {
    id: 2,
    name: "Front-End Developer",
    emoji: "🧑‍🎨",
    kind: "category",
  },
  {
    id: 3,
    name: "Full Stack Developer",
    emoji: "🥞",
    kind: "category",
  },
  {
    id: 4,
    name: "Back-End Developer",
    emoji: "💻",
    kind: "category",
  },
  {
    id: 5,
    name: "Junior",
    emoji: "🐥",
    kind: "category",
  },
  {
    id: 6,
    name: "Cloud",
    emoji: "☁️",
    kind: "category",
  },
  {
    id: 7,
    name: "Design",
    emoji: "🎨",
    kind: "category",
  },
  {
    id: 8,
    name: "Customer Support",
    emoji: "👾",
    kind: "category",
  },
  {
    id: 9,
    name: "Product",
    emoji: "📦",
    kind: "category",
  },
  {
    id: 10,
    name: "Other",
    emoji: "🤠",
    kind: "category",
  },
  {
    id: 11,
    name: "EMEA",
    emoji: "🇪🇺",
    kind: "location",
  },
  {
    id: 12,
    name: "US",
    emoji: "🌎",
    kind: "location",
  },
  {
    id: 13,
    name: "APAC",
    emoji: "⛩",
    kind: "location",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function JobCategorySelect({ onFilterSelection }) {
  const [selectedPerson, setSelectedPerson] = useState(null);

  const onCategorySelect = (event) => {
    console.log("onCategorySelect", selectedPerson);
    setSelectedPerson(event);
    if (event && event.kind) {
      onFilterSelection({ [event.kind]: event.name });
    } else {
      onFilterSelection();
    }
  };

  const clearFilter = () => {
    setSelectedPerson(null);
    onFilterSelection();
  };

  return (
    <div className="grid">
      <h1 className="font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-center">
        Find your next Job
      </h1>
      <Combobox
        className="mx-auto w-1/2 place-content-center"
        as="div"
        value={selectedPerson}
        onChange={onCategorySelect}
      >
        <div className="relative mt-1">
          <Combobox.Button as="div">
            <Combobox.Input
              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              displayValue={(person) => {
                if (person) return `${person.emoji}${person.name}`;
              }}
            />
          </Combobox.Button>

          {people.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <Emoji symbol={person.emoji} label={person.name} />
                        <span
                          className={classNames(
                            "ml-3 truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {person.name}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      <div>
        {selectedPerson && selectedPerson.name && (
          <div className="">
            <div className="px-4 py-5 sm:p-6">
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-red-500 bg-red-500 px-4 py-2 font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                onClick={clearFilter}
              >
                {I18n.get("jobsEmptyButton")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
