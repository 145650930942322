import React from "react";

import Home from "./../components/Home";
import Header from "./../components/Header";
import Footer from "./../components/Footer";

export default function Root() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}
